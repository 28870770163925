<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="star-gamefram" scrolling="no" frameborder="0"
                    src="http://speedgame2.com/game/pdari/"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">황금돼지 사다리 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ oddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ oddevenConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">좌/우 출발</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,1, leftrightConfig.odds1, leftrightConfig, '좌출발')">
                <div class="r rblue">
                  <span class="n">좌</span>
                  <span class="b">{{ leftrightConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,2, leftrightConfig.odds2, leftrightConfig, '우출발')">
                <div class="r rred">
                  <span class="n">우</span>
                  <span class="b">{{ leftrightConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">3줄/4줄</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT,leisureGameInfo.leisureGame.id,1, lineConfig.odds1, lineConfig, '3줄')">
                <div class="r rblue">
                  <span class="n">3줄</span>
                  <span class="b">{{ lineConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT,leisureGameInfo.leisureGame.id,2, lineConfig.odds2, lineConfig, '4줄')">
                <div class="r rred">
                  <span class="n">4줄</span>
                  <span class="b">{{ lineConfig.odds2 }}</span>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            황금돼지 사다리
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "SpeedGamePDALI",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_SPEEDGAME_PDALI,

    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.oddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_ODDEVEN)
          })

          this.leftrightConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LEFT_RIGHT)
          })

          this.lineConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SPEEDGAME_PDALI_LINE_COUNT)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_GAMEMOA);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
}
</script>

<style scoped>

</style>